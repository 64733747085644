import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "hooks/useAdmin";
import { useToastContext } from "contexts/ToastContext";
import styles from "./ContentDetail.module.css";
import Button from "components/Button/Button";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { BsExclamationTriangle, BsArrowLeft } from "react-icons/bs";
import ContentActionModal from "../../components/ContentActionModal";
import { FaHistory } from "react-icons/fa";

const ContentDetail = ({ contentType, contentId }) => {
  const navigate = useNavigate();
  const { addToast } = useToastContext();
  const {
    contentDetails,
    fetchContentDetails,
    flagContent,
    removeContent,
    restoreContent,
    contentAdminActions,
    fetchContentAdminActions,
  } = useAdmin();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [actionModalState, setActionModalState] = useState({
    isOpen: false,
    actionType: null,
    isSubmitting: false,
  });
  const [activeTab, setActiveTab] = useState("details");
  const [adminActionsLoading, setAdminActionsLoading] = useState(false);
  const [adminActionsError, setAdminActionsError] = useState(null);

  useEffect(() => {
    if (contentType && contentId) {
      loadContent();
    } else {
      setError("Invalid content type or ID provided.");
      setIsLoading(false);
    }
  }, [contentType, contentId]);

  const loadContent = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await fetchContentDetails(contentType, contentId);
    } catch (error) {
      console.error("Error loading content details:", error);
      setError("Failed to load content details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const loadAdminActions = async () => {
    if (!contentType || !contentId) return;

    setAdminActionsLoading(true);
    setAdminActionsError(null);

    try {
      await fetchContentAdminActions(contentType, contentId);
    } catch (error) {
      setAdminActionsError("Failed to load audit log");
      console.error("Error loading content admin actions:", error);
    } finally {
      setAdminActionsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "audit-log" && contentType && contentId) {
      loadAdminActions();
    }
  }, [activeTab, contentType, contentId]);

  const handleAction = (actionType) => {
    setActionModalState({
      isOpen: true,
      actionType,
      isSubmitting: false,
    });
  };

  const handleCloseModal = () => {
    setActionModalState((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleSubmitAction = async (data) => {
    setActionModalState((prev) => ({
      ...prev,
      isSubmitting: true,
    }));

    try {
      const actionFn = {
        Flag: flagContent,
        Remove: removeContent,
        Restore: restoreContent,
      }[actionModalState.actionType];

      await actionFn(contentType, contentId, { reason: data.reason });

      addToast(
        `Content ${actionModalState.actionType.toLowerCase()}d successfully`,
        "success"
      );
      loadContent();
      // Also refresh audit log if we're on that tab
      if (activeTab === "audit-log") {
        loadAdminActions();
      }
      setActionModalState({
        isOpen: false,
        actionType: null,
        isSubmitting: false,
      });
    } catch (error) {
      addToast(
        `Failed to ${actionModalState.actionType.toLowerCase()} content: ${
          error.message
        }`,
        "error"
      );
      setActionModalState((prev) => ({
        ...prev,
        isSubmitting: false,
      }));
    }
  };

  const getActionButtons = () => {
    const buttons = [];

    if (contentDetails?.status === "active") {
      buttons.push(
        <Button key="flag" color="warning" onClick={() => handleAction("Flag")}>
          Flag for Review
        </Button>
      );
      buttons.push(
        <Button
          key="remove"
          color="error"
          onClick={() => handleAction("Remove")}
        >
          Remove Content
        </Button>
      );
    }

    if (["flagged", "removed"].includes(contentDetails?.status)) {
      buttons.push(
        <Button
          key="restore"
          color="success"
          onClick={() => handleAction("Restore")}
        >
          Restore Content
        </Button>
      );
    }

    // Only return the container if we have buttons to show
    return buttons.length > 0 ? buttons : null;
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const renderContentBody = () => {
    if (!contentDetails) return null;

    // Check if it's a Story by looking for title and body fields
    if (contentDetails.title && contentDetails.body) {
      return (
        <div className={styles.contentBody}>
          <h3>{contentDetails.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: contentDetails.body }} />
        </div>
      );
    } else {
      // It's a LifeExperience
      return (
        <div className={styles.contentBody}>
          <p className={styles.experienceText}>{contentDetails.story}</p>
          {contentDetails.intents && (
            <div className={styles.intentsSection}>
              <h4>User Intents:</h4>
              <div className={styles.intentsList}>
                {contentDetails.intents.venting && (
                  <span className={styles.intent}>Venting</span>
                )}
                {contentDetails.intents.seeking_advice && (
                  <span className={styles.intent}>Seeking Advice</span>
                )}
                {contentDetails.intents.offering_support && (
                  <span className={styles.intent}>Offering Support</span>
                )}
                {contentDetails.intents.sharing_experience && (
                  <span className={styles.intent}>Sharing Experience</span>
                )}
              </div>
            </div>
          )}
          {contentDetails.date && (
            <div className={styles.dateInfo}>
              <span className={styles.dateLabel}>Experience date:</span>
              <span className={styles.dateValue}>
                {new Date(contentDetails.date.iso).toLocaleDateString(
                  undefined,
                  {
                    year: "numeric",
                    month: "long",
                    day: contentDetails.date.month ? undefined : "numeric",
                  }
                )}
              </span>
            </div>
          )}
        </div>
      );
    }
  };

  // Helper function to format metadata for display
  const formatMetadata = (metadata) => {
    if (!metadata || Object.keys(metadata).length === 0) return "";

    // Format dates in a more readable way
    const formatted = {};

    Object.entries(metadata).forEach(([key, value]) => {
      if (key.toLowerCase().includes("date") && value) {
        // Format date values
        formatted[key] = new Date(value).toLocaleString();
      } else if (key === "previousStatus") {
        // Capitalize status
        formatted[key] = value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        formatted[key] = value;
      }
    });

    return JSON.stringify(formatted, null, 2);
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <BsExclamationTriangle size={48} />
        <p>{error}</p>
        <Button onClick={loadContent}>Try Again</Button>
      </div>
    );
  }

  if (!contentDetails) {
    return (
      <div className={styles.errorContainer}>
        <p>Content not found</p>
        <Button onClick={() => navigate("/admin/content")}>
          Back to Content List
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.contentDetailContainer}>
      <div className={styles.contentDetailHeader}>
        <Button
          className={styles.backButton}
          onClick={() => navigate("/admin/content")}
        >
          <BsArrowLeft /> Back to Content List
        </Button>
        <div className={styles.contentStatus}>
          <span className={styles[`status-${contentDetails.status}`]}>
            {contentDetails.status}
          </span>
        </div>
      </div>

      <div className={styles.contentDetailContent}>
        <div className={styles.contentOverview}>
          <div className={styles.contentDetailProfile}>
            <div className={styles.contentInfo}>
              <h2>
                {contentDetails.title
                  ? contentDetails.title
                  : "Life Experience"}
              </h2>
              <p>
                {contentType === "story" ? "Story" : "Life Experience"} by{" "}
                <a
                  className={styles.authorLink}
                  onClick={() =>
                    navigate(`/admin/users/${contentDetails.author?._id}`)
                  }
                >
                  {contentDetails.author?.username || "Unknown"}
                </a>
              </p>
              <div className={styles.contentMeta}>
                <span className={styles[`status-${contentDetails.status}`]}>
                  {contentDetails.status}
                </span>
                <span className={styles.contentCreated}>
                  Created: {formatDate(contentDetails.createdAt)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.actionButtons}>{getActionButtons()}</div>

        {/* Add tabs for Details and Audit Log */}
        <div className={styles.contentDetailTabs}>
          <button
            className={activeTab === "details" ? styles.activeTab : ""}
            onClick={() => setActiveTab("details")}
          >
            Details
          </button>
          <button
            className={activeTab === "audit-log" ? styles.activeTab : ""}
            onClick={() => setActiveTab("audit-log")}
          >
            <FaHistory /> Audit Log
          </button>
        </div>

        {activeTab === "details" && (
          <div className={styles.contentInfo}>
            <div className={styles.infoSection}>
              <h3>Basic Information</h3>
              <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                  <span className={styles.infoLabel}>Type:</span>
                  <span className={styles.infoValue}>
                    {contentType === "story" ? "Story" : "Life Experience"}
                  </span>
                </div>
                <div className={styles.infoItem}>
                  <span className={styles.infoLabel}>Status:</span>
                  <span className={styles.infoValue}>
                    <span className={styles[`status-${contentDetails.status}`]}>
                      {contentDetails.status}
                    </span>
                  </span>
                </div>
                <div className={styles.infoItem}>
                  <span className={styles.infoLabel}>Author:</span>
                  <span className={styles.infoValue}>
                    <a
                      className={styles.authorLink}
                      onClick={() =>
                        navigate(`/admin/users/${contentDetails.author?._id}`)
                      }
                    >
                      {contentDetails.author?.username || "Unknown"}
                    </a>
                    {contentDetails.anonymized && " (Anonymized as "}
                    {contentDetails.anonymized &&
                      contentDetails.authorPseudonym && (
                        <span className={styles.pseudonym}>
                          {contentDetails.authorPseudonym}
                        </span>
                      )}
                    {contentDetails.anonymized && ")"}
                  </span>
                </div>
                <div className={styles.infoItem}>
                  <span className={styles.infoLabel}>Created:</span>
                  <span className={styles.infoValue}>
                    {formatDate(contentDetails.createdAt)}
                  </span>
                </div>
                <div className={styles.infoItem}>
                  <span className={styles.infoLabel}>Updated:</span>
                  <span className={styles.infoValue}>
                    {formatDate(contentDetails.updatedAt)}
                  </span>
                </div>

                {contentType === "lifeexperience" && (
                  <div className={styles.infoItem}>
                    <span className={styles.infoLabel}>Permissions:</span>
                    <span className={styles.infoValue}>
                      <div className={styles.permissionsList}>
                        <span
                          className={
                            contentDetails.searchable
                              ? styles.permissionEnabled
                              : styles.permissionDisabled
                          }
                        >
                          Searchable
                        </span>
                        <span
                          className={
                            contentDetails.publicPermission
                              ? styles.permissionEnabled
                              : styles.permissionDisabled
                          }
                        >
                          Public Permission
                        </span>
                        <span
                          className={
                            contentDetails.featured
                              ? styles.permissionEnabled
                              : styles.permissionDisabled
                          }
                        >
                          Featured
                        </span>
                      </div>
                    </span>
                  </div>
                )}

                {contentDetails.tags && contentDetails.tags.length > 0 && (
                  <div className={styles.infoItem}>
                    <span className={styles.infoLabel}>Tags:</span>
                    <span className={styles.infoValue}>
                      <div className={styles.tagsList}>
                        {contentDetails.tags.map((tag) => (
                          <span key={tag._id} className={styles.tag}>
                            {tag.name}
                          </span>
                        ))}
                      </div>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.contentSection}>
              <h3>Content</h3>
              {renderContentBody()}
            </div>

            {contentDetails.moderationHistory &&
              contentDetails.moderationHistory.length > 0 && (
                <div className={styles.moderationSection}>
                  <h3>Moderation History</h3>
                  <div className={styles.moderationHistory}>
                    {contentDetails.moderationHistory.map((action, index) => (
                      <div key={index} className={styles.moderationAction}>
                        <div className={styles.actionHeader}>
                          <span className={styles.actionType}>
                            {action.actionType.replace("_", " ").toUpperCase()}
                          </span>
                          <span className={styles.actionDate}>
                            {formatDate(action.createdAt)}
                          </span>
                        </div>
                        <div className={styles.actionDetails}>
                          <span className={styles.actionBy}>
                            By: {action.performedBy?.username || "Unknown"}
                          </span>
                          <span className={styles.actionReason}>
                            Reason: {action.reason}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

            {contentDetails.reports && contentDetails.reports.length > 0 && (
              <div className={styles.reportsSection}>
                <h3>Reports</h3>
                <div className={styles.reportsList}>
                  {contentDetails.reports.map((report, index) => (
                    <div
                      key={index}
                      className={styles.reportItem}
                      onClick={() => navigate(`/admin/reports/${report._id}`)}
                    >
                      <div className={styles.reportHeader}>
                        <span className={styles.reportDate}>
                          {formatDate(report.createdAt)}
                        </span>
                        <span
                          className={`${styles.reportStatus} ${
                            styles[`status-${report.status}`]
                          }`}
                        >
                          {report.status}
                        </span>
                      </div>
                      <div className={styles.reportDetails}>
                        <span className={styles.reportedBy}>
                          Reported by:{" "}
                          {report.reporter?.username || "Anonymous"}
                        </span>
                        <span className={styles.reportReason}>
                          Reason: {report.reason}
                        </span>
                        {report.description && (
                          <span className={styles.reportDescription}>
                            Description: {report.description}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {activeTab === "audit-log" && (
          <div className={styles.auditLogTab}>
            <div className={styles.auditLogSection}>
              <h3>Admin Actions</h3>
              {adminActionsLoading ? (
                <div className={styles.loadingSpinner}>
                  <LoadingSpinner />
                </div>
              ) : adminActionsError ? (
                <div className={styles.errorState}>
                  <BsExclamationTriangle size={32} />
                  <p>{adminActionsError}</p>
                  <Button onClick={loadAdminActions}>Try Again</Button>
                </div>
              ) : contentAdminActions.length === 0 ? (
                <div className={styles.emptyState}>
                  <p>No admin actions have been performed on this content.</p>
                </div>
              ) : (
                <div className={styles.adminActionsList}>
                  {contentAdminActions.map((action) => (
                    <div
                      key={action.id}
                      className={styles.adminActionItem}
                      data-action-type={action.actionType}
                    >
                      <div className={styles.adminActionHeader}>
                        <span className={styles.adminActionType}>
                          {action.actionType.replace(/_/g, " ")}
                        </span>
                        <span className={styles.adminActionDate}>
                          {new Date(action.timestamp).toLocaleDateString()} at{" "}
                          {new Date(action.timestamp).toLocaleTimeString()}
                        </span>
                      </div>
                      <div className={styles.adminActionContent}>
                        <div className={styles.adminActionReason}>
                          <span className={styles.adminActionLabel}>
                            Reason:
                          </span>
                          <span className={styles.adminActionValue}>
                            {action.reason}
                          </span>
                        </div>
                        <div className={styles.adminActionModerator}>
                          <span className={styles.adminActionLabel}>
                            Performed by:
                          </span>
                          <span className={styles.adminActionValue}>
                            {action.moderator?.username || "System"}
                          </span>
                        </div>
                        {action.metadata &&
                          Object.keys(action.metadata).length > 0 && (
                            <div className={styles.adminActionMetadata}>
                              <span className={styles.adminActionLabel}>
                                Details:
                              </span>
                              <pre
                                className={styles.adminActionMetadataContent}
                              >
                                {formatMetadata(action.metadata)}
                              </pre>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <ContentActionModal
        isOpen={actionModalState.isOpen}
        onClose={handleCloseModal}
        actionType={actionModalState.actionType}
        content={
          contentDetails && {
            ...contentDetails,
            contentType: contentType === "story" ? "Story" : "LifeExperience",
          }
        }
        onSubmit={handleSubmitAction}
        isSubmitting={actionModalState.isSubmitting}
      />
    </div>
  );
};

export default ContentDetail;

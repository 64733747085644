import { useEffect, useState, useCallback } from "react";
import styles from "./ContentManagement.module.css";
import { useAdmin } from "hooks/useAdmin";
import {
  BsThreeDotsVertical,
  BsExclamationTriangle,
  BsSearch,
  BsFilter,
} from "react-icons/bs";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Button from "components/Button/Button";
import { useToastContext } from "contexts/ToastContext";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";
import { useNavigate } from "react-router-dom";
import ContentActionModal from "../components/ContentActionModal";

// Constants for filtering and display
const CONTENT_TYPE_OPTIONS = [
  { value: "all", label: "All Content" },
  { value: "Story", label: "Stories" },
  { value: "LifeExperience", label: "Life Experiences" },
];

const STATUS_OPTIONS = [
  { value: "", label: "All" },
  { value: "active", label: "Active" },
  { value: "flagged", label: "Flagged" },
  { value: "removed", label: "Removed" },
  { value: "deleted", label: "Deleted" },
];

const SORT_OPTIONS = [
  { value: "-createdAt", label: "Newest First" },
  { value: "createdAt", label: "Oldest First" },
  { value: "-updatedAt", label: "Recently Updated" },
];

const ContentManagement = () => {
  const { addToast } = useToastContext();
  const navigate = useNavigate();

  // Assuming these functions will be added to useAdmin hook
  const {
    fetchAllContent,
    flagContent,
    removeContent,
    restoreContent,
    contentData,
    contentPagination,
    contentStats,
    fetchContentStats,
  } = useAdmin();

  // State for content data
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  const [actionModalState, setActionModalState] = useState({
    isOpen: false,
    actionType: null,
    content: null,
    isSubmitting: false,
  });

  // Filtering and sorting states
  const [searchTerm, setSearchTerm] = useState("");
  const [contentTypeFilter, setContentTypeFilter] = useState("all");
  const [statusFilters, setStatusFilters] = useState({
    active: false,
    flagged: false,
    removed: false,
    deleted: false,
  });
  const [sortOption, setSortOption] = useState("-createdAt");
  const [page, setPage] = useState(1);

  // Track if filters have changed since last search
  const [filtersChanged, setFiltersChanged] = useState(false);

  // Create a memoized loadContent function that doesn't change on re-renders
  const loadContent = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setFiltersChanged(false);

    try {
      const selectedStatuses = Object.entries(statusFilters)
        .filter(([_, isSelected]) => isSelected)
        .map(([status]) => status);

      await fetchAllContent({
        contentType: contentTypeFilter,
        status:
          selectedStatuses.length > 0 ? selectedStatuses.join(",") : undefined,
        page,
        limit: 50,
        sort: sortOption,
        search: searchTerm || undefined,
      });
    } catch (error) {
      console.error("Error loading content:", error);
      setError("Failed to load content. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [contentTypeFilter, page, searchTerm, sortOption, statusFilters]);

  // Initial load effect
  useEffect(() => {
    fetchContentStats();
    loadContent();
  }, []);

  // Handle pagination and sorting changes automatically
  useEffect(() => {
    // Only trigger if it's not the initial render
    if (filtersChanged) {
      loadContent();
    }
  }, [page, sortOption, loadContent, filtersChanged]);

  // Update filtersChanged flag when filters change
  useEffect(() => {
    setFiltersChanged(true);
  }, [contentTypeFilter, JSON.stringify(statusFilters)]);

  // Handle filter changes
  const handleContentTypeChange = (value) => {
    setContentTypeFilter(value);
    setPage(1); // Reset to first page
    setFiltersChanged(true);
  };

  const handleStatusFilterChange = (status) => {
    setStatusFilters((prev) => ({
      ...prev,
      [status]: !prev[status],
    }));
    setPage(1); // Reset to first page
    setFiltersChanged(true);
  };

  // Handle search submission
  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); // Reset to first page
    loadContent();
  };

  // Clear all filters
  const clearFilters = () => {
    setSearchTerm("");
    setContentTypeFilter("all");
    setStatusFilters({
      active: false,
      flagged: false,
      removed: false,
      deleted: false,
    });
    setSortOption("-createdAt");
    setPage(1);
    // Immediately load with cleared filters
    loadContent();
  };

  // Handle content row click to view details
  const handleRowClick = (contentType, contentId) => {
    // Convert to lowercase for URL
    const urlType =
      contentType === "LifeExperience"
        ? "life-experience"
        : contentType.toLowerCase();
    navigate(`/admin/content/${urlType}/${contentId}`);
  };

  // Get action options for content item
  const getContentActionOptions = (content) => {
    const actions = [];

    // View details action
    actions.push({
      label: "View Details",
      onClick: () => handleRowClick(content.contentType, content._id),
      color: "default",
    });

    // Flag action (only for active content)
    if (content.status === "active") {
      actions.push({
        label: "Flag for Review",
        onClick: () => handleContentAction("Flag", content),
        color: "warning",
      });
    }

    // Remove action (for active or flagged content)
    if (["active", "flagged"].includes(content.status)) {
      actions.push({
        label: "Remove Content",
        onClick: () => handleContentAction("Remove", content),
        color: "error",
      });
    }

    // Restore action (for flagged or removed content)
    if (["flagged", "removed"].includes(content.status)) {
      actions.push({
        label: "Restore Content",
        onClick: () => handleContentAction("Restore", content),
        color: "success",
      });
    }

    return actions;
  };

  // Handle content moderation actions
  const handleContentAction = (action, content) => {
    setActionModalState({
      isOpen: true,
      actionType: action,
      content,
      isSubmitting: false,
    });
  };

  const handleCloseModal = () => {
    setActionModalState((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleSubmitAction = async (data) => {
    setActionModalState((prev) => ({
      ...prev,
      isSubmitting: true,
    }));

    try {
      const actionFn = {
        Flag: flagContent,
        Remove: removeContent,
        Restore: restoreContent,
      }[actionModalState.actionType];

      await actionFn(data.contentType, data.contentId, { reason: data.reason });

      addToast(
        `Content ${actionModalState.actionType.toLowerCase()}d successfully`,
        "success"
      );
      loadContent(); // Refresh the content list
      setActionModalState({
        isOpen: false,
        actionType: null,
        content: null,
        isSubmitting: false,
      });
    } catch (error) {
      addToast(
        `Failed to ${actionModalState.actionType.toLowerCase()} content: ${
          error.message
        }`,
        "error"
      );
      setActionModalState((prev) => ({
        ...prev,
        isSubmitting: false,
      }));
    }
  };

  // Use the combined content array directly
  const allContent = contentData?.items || [];

  console.log("allContent", allContent);

  // Add this function to handle header clicks for sorting
  const handleSortChange = (field) => {
    let newSortOption;

    // If clicking the same field, toggle direction
    if (sortOption === field) {
      newSortOption = `-${field}`;
    } else if (sortOption === `-${field}`) {
      newSortOption = field;
    } else {
      // Default to descending order when selecting a new field
      newSortOption = `-${field}`;
    }

    setSortOption(newSortOption);
    setPage(1); // Reset to first page

    // Force a reload with the new sort option
    setTimeout(() => {
      loadContent();
    }, 0);
  };

  // Helper function to render sort icon
  const renderSortIcon = (field) => {
    if (sortOption === field) {
      // Ascending order (oldest first) - arrow points down
      return <FaSortAmountDown className={styles.sortIcon} />;
    } else if (sortOption === `-${field}`) {
      // Descending order (newest first) - arrow points up
      return <FaSortAmountUp className={styles.sortIcon} />;
    }
    return null;
  };

  return (
    <div className={styles.contentManagementContainer}>
      <div className={styles.header}>
        <h1>Content Management</h1>
        <div className={styles.stats}>
          <div className={styles.statItem}>
            <span className={styles.statValue}>{contentStats.total || 0}</span>
            <span className={styles.statLabel}>Total</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statValue}>
              {contentStats.storiesTotal || 0}
            </span>
            <span className={styles.statLabel}>Stories</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statValue}>
              {contentStats.lifeExperiencesTotal || 0}
            </span>
            <span className={styles.statLabel}>Life Experiences</span>
          </div>
        </div>
      </div>

      <div className={styles.filters}>
        <form className={styles.searchForm} onSubmit={handleSearch}>
          <div className={styles.searchInput}>
            <input
              type="text"
              placeholder="Search content..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              aria-label="Search content"
            />
            <button type="submit">
              <BsSearch />
            </button>
          </div>
        </form>

        <div className={styles.filterControls}>
          <div className={styles.filterGroup}>
            <label>
              <BsFilter /> Content Type:
              <select
                value={contentTypeFilter}
                onChange={(e) => handleContentTypeChange(e.target.value)}
              >
                {CONTENT_TYPE_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className={styles.checkboxFilters}>
            <span className={styles.filterLabel}>Status:</span>
            <div className={styles.checkboxGroup}>
              <label>
                <input
                  type="checkbox"
                  checked={statusFilters.active}
                  onChange={() => handleStatusFilterChange("active")}
                />
                <span className={styles["status-active"]}>Active</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={statusFilters.flagged}
                  onChange={() => handleStatusFilterChange("flagged")}
                />
                <span className={styles["status-flagged"]}>Flagged</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={statusFilters.removed}
                  onChange={() => handleStatusFilterChange("removed")}
                />
                <span className={styles["status-removed"]}>Removed</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={statusFilters.deleted}
                  onChange={() => handleStatusFilterChange("deleted")}
                />
                <span className={styles["status-deleted"]}>Deleted</span>
              </label>
            </div>
          </div>

          <div className={styles.filterGroup}>
            <label>
              <BsFilter /> Sort By:
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
              >
                {SORT_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <Button
            onClick={clearFilters}
            color="secondary"
            className={styles.clearButton}
          >
            Clear Filters
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      ) : error ? (
        <div className={styles.errorContainer}>
          <BsExclamationTriangle size={48} />
          <p>{error}</p>
          <Button onClick={loadContent}>Try Again</Button>
        </div>
      ) : (
        <div className={styles.tableWrapper}>
          <table className={styles.contentTable}>
            <thead>
              <tr>
                <th>Type</th>
                <th>Title/Content</th>
                <th>Author</th>
                <th>Status</th>
                <th
                  className={styles.sortableHeader}
                  onClick={() => handleSortChange("createdAt")}
                >
                  Created At {renderSortIcon("createdAt")}
                </th>
                <th
                  className={styles.sortableHeader}
                  onClick={() => handleSortChange("updatedAt")}
                >
                  Updated At {renderSortIcon("updatedAt")}
                </th>
                <th className={styles.actionCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allContent.length === 0 ? (
                <tr>
                  <td colSpan="7" className={styles.noResults}>
                    No content found matching your criteria
                  </td>
                </tr>
              ) : (
                allContent.map((content) => (
                  <tr
                    key={`${content.contentType}-${content._id}`}
                    className={styles.contentRow}
                    onClick={() =>
                      handleRowClick(content.contentType, content._id)
                    }
                  >
                    <td className={styles.contentDataCell}>
                      {content.contentType === "Story"
                        ? "Story"
                        : "Life Experience"}
                    </td>
                    <td className={styles.contentDataCell}>
                      <div className={styles.contentPreview}>
                        <div className={styles.contentTitle}>
                          {content.contentType === "Story"
                            ? content.title || "Untitled Story"
                            : "Life Experience"}
                        </div>
                        <div className={styles.contentExcerpt}>
                          {content.contentType === "Story"
                            ? content.text?.substring(0, 100) +
                              (content.text?.length > 100 ? "..." : "")
                            : content.story?.substring(0, 100) +
                              (content.story?.length > 100 ? "..." : "")}
                        </div>
                      </div>
                    </td>
                    <td className={styles.contentDataCell}>
                      {content.author?.username || "Unknown"}
                    </td>
                    <td className={styles.contentDataCell}>
                      <span className={styles[`status-${content.status}`]}>
                        {content.status}
                      </span>
                    </td>
                    <td className={styles.contentDataCell}>
                      {new Date(content.createdAt).toLocaleDateString()}
                    </td>
                    <td className={styles.contentDataCell}>
                      {new Date(content.updatedAt).toLocaleDateString()}
                    </td>
                    <td
                      className={styles.actionCell}
                      onClick={(e) => e.stopPropagation()} // Prevent row click when clicking on actions
                    >
                      <ReusableActionMenu
                        options={getContentActionOptions(content)}
                        icon={BsThreeDotsVertical}
                        menuHeader="Content Actions"
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination controls */}
      {!isLoading && !error && allContent.length > 0 && (
        <div className={styles.pagination}>
          <Button
            onClick={() => setPage(Math.max(1, page - 1))}
            disabled={page === 1 || isLoading}
          >
            Previous
          </Button>
          <span>
            Page {page} of {contentPagination?.pages || 1}
          </span>
          <Button
            onClick={() =>
              setPage(Math.min(contentPagination?.pages || 1, page + 1))
            }
            disabled={page >= (contentPagination?.pages || 1) || isLoading}
          >
            Next
          </Button>
        </div>
      )}

      <ContentActionModal
        isOpen={actionModalState.isOpen}
        onClose={handleCloseModal}
        actionType={actionModalState.actionType}
        content={actionModalState.content}
        onSubmit={handleSubmitAction}
        isSubmitting={actionModalState.isSubmitting}
      />
    </div>
  );
};

export default ContentManagement;

import { useState } from "react";
import styles from "./ActionModals.module.css";
import Button from "components/Button/Button";
import {
  useFloating,
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useRole,
  useDismiss,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";

/**
 * A reusable modal component for content moderation actions in the admin dashboard
 * using Floating UI for better positioning and accessibility
 *
 * @param {Object} props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to call when closing the modal
 * @param {string} props.actionType - The type of action (Flag, Remove, Restore)
 * @param {Object} props.content - The content object the action is being performed on
 * @param {Function} props.onSubmit - Function to call when submitting the action
 * @param {boolean} props.isSubmitting - Whether the action is currently being submitted
 */
const ContentActionModal = ({
  isOpen,
  onClose,
  actionType,
  content,
  onSubmit,
  isSubmitting,
}) => {
  const [actionReason, setActionReason] = useState("");

  // Set up floating UI
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const click = useClick(context);
  const role = useRole(context, { role: "dialog" });
  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([click, role, dismiss]);

  // Define proper action phrases for different contexts
  const ACTION_PHRASES = {
    Flag: {
      reasonLabel: "Reason for flagging",
      reasonPlaceholder: "Enter detailed reason for flagging this content...",
      buttonText: "Flag",
    },
    Remove: {
      reasonLabel: "Reason for removal",
      reasonPlaceholder: "Enter detailed reason for removing this content...",
      buttonText: "Remove",
    },
    Restore: {
      reasonLabel: "Reason for restoration",
      reasonPlaceholder: "Enter detailed reason for restoring this content...",
      buttonText: "Restore",
    },
  };

  // Get the appropriate phrases for the current action type
  const actionPhrases = ACTION_PHRASES[actionType] || {
    reasonLabel: `Reason for ${
      actionType ? actionType.toLowerCase() : "this action"
    }`,
    reasonPlaceholder: `Enter detailed reason for ${
      actionType ? actionType.toLowerCase() : "this action"
    }...`,
    buttonText: actionType || "Submit",
  };

  const handleSubmit = () => {
    const data = {
      contentId: content._id,
      contentType: content.contentType,
      reason: actionReason,
    };

    onSubmit(data);
  };

  const handleClose = () => {
    setActionReason(""); // Reset form on close
    onClose();
  };

  // Handle overlay background click manually
  const handleOverlayClick = (e) => {
    // Only close if clicking directly on the overlay (not on the modal or its children)
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  if (!isOpen || !content) return null;

  // Determine warning message based on action type
  const getWarningMessage = () => {
    switch (actionType) {
      case "Flag":
        return "This will mark the content for review by other moderators.";
      case "Remove":
        return "This will remove the content from public view for violating guidelines.";
      case "Restore":
        if (content.status === "flagged") {
          return "This will restore the content from being flagged, making it fully visible again.";
        } else if (content.status === "removed") {
          return "This will restore the removed content, making it publicly accessible again.";
        }
        return "This will restore the content to active status.";
      default:
        return null;
    }
  };

  const getContentTitle = () => {
    if (content.title) {
      return content.title;
    } else if (content.story) {
      return (
        content.story.substring(0, 50) +
        (content.story.length > 50 ? "..." : "")
      );
    } else {
      return `${content.contentType} ${content._id}`;
    }
  };

  return (
    <FloatingPortal>
      <FloatingOverlay
        lockScroll
        className={styles.overlay}
        onClick={handleOverlayClick}
      >
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            className={styles.floatingModal}
            {...getFloatingProps()}
          >
            <div className={styles.modalHeader}>
              <h3>{actionType} Content</h3>
              <button
                onClick={handleClose}
                className={styles.closeButton}
                aria-label="Close modal"
                disabled={isSubmitting}
              >
                ×
              </button>
            </div>

            <div className={styles.resourceIdentifier}>
              Content ID: {content._id}
            </div>

            <div className={styles.resourceDetails}>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Type:</span>
                <span className={styles.detailValue}>
                  {content.contentType === "Story"
                    ? "Story"
                    : "Life Experience"}
                </span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Title:</span>
                <span className={styles.detailValue}>{getContentTitle()}</span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Author:</span>
                <span className={styles.detailValue}>
                  {content.author?.username || "Unknown"}
                </span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Status:</span>
                <span className={styles.detailValue}>
                  <span className={styles[`status-${content.status}`]}>
                    {content.status}
                  </span>
                </span>
              </div>
            </div>

            <div className={styles.modalContent}>
              <div className={styles.formGroup}>
                <label htmlFor="reason">{actionPhrases.reasonLabel}:</label>
                <textarea
                  id="reason"
                  placeholder={actionPhrases.reasonPlaceholder}
                  value={actionReason}
                  onChange={(e) => setActionReason(e.target.value)}
                  disabled={isSubmitting}
                />
                {getWarningMessage() && (
                  <p className={styles.warningText}>{getWarningMessage()}</p>
                )}
              </div>

              <div className={styles.modalActions}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={!actionReason || isSubmitting}
                  color={
                    actionType === "Remove"
                      ? "error"
                      : actionType === "Flag"
                      ? "warning"
                      : "success"
                  }
                >
                  {isSubmitting ? "Processing..." : actionPhrases.buttonText}
                </Button>
              </div>
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default ContentActionModal;

import { useState } from "react";
import * as adminAPI from "api/admin";

export const useAdmin = () => {
  const [users, setUsers] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [reports, setReports] = useState([]);
  const [reportsPagination, setReportsPagination] = useState({
    pages: 1,
    page: 1,
    total: 0,
    limit: 10,
  });
  const [pagination, setPagination] = useState({
    pages: 1,
    page: 1,
    total: 0,
    limit: 10,
  });
  const [userContent, setUserContent] = useState({
    stories: [],
    lifeExperiences: [],
    pagination: {
      page: 1,
      limit: 10,
      total: 0,
      pages: 1,
    },
  });
  const [userReports, setUserReports] = useState({
    filed: {
      reports: [],
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        pages: 1,
      },
    },
    received: {
      reports: [],
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        pages: 1,
      },
    },
  });
  const [reportDetails, setReportDetails] = useState(null);
  const [userAdminActions, setUserAdminActions] = useState([]);
  const [contentData, setContentData] = useState({
    items: [],
  });
  const [contentPagination, setContentPagination] = useState({
    page: 1,
    limit: 50,
    total: 0,
    pages: 1,
    storiesTotal: 0,
    lifeExperiencesTotal: 0,
  });
  const [contentStats, setContentStats] = useState({
    total: 0,
    storiesTotal: 0,
    lifeExperiencesTotal: 0,
    active: 0,
    flagged: 0,
    removed: 0,
    deleted: 0,
  });
  const [contentDetails, setContentDetails] = useState(null);
  const [contentAdminActions, setContentAdminActions] = useState([]);

  const fetchAllUsers = async (params = {}) => {
    try {
      const data = await adminAPI.fetchUsers(params);

      // Handle the response format from your backend
      if (data.success && data.users) {
        setUsers(data.users);

        // Update pagination based on the response format
        if (data.pagination) {
          setPagination({
            pages: data.pagination.pages,
            page: data.pagination.page,
            total: data.pagination.total,
            limit: data.pagination.limit,
          });
        }
      }

      return data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  };

  const fetchUserDetail = async (userId) => {
    try {
      const data = await adminAPI.fetchUserDetails(userId);

      // Store the user object from the response
      if (data && data.user) {
        setUserDetails({
          ...data.user,
          content: data.content, // Store content counts directly on userDetails
        });

        // Return the user object
        return data.user;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      throw error;
    }
  };

  const approveUserAccount = async (data) => {
    try {
      const res = await adminAPI.approveUser(data);
      return res;
    } catch (error) {
      console.error("Error approving user:", error);
      throw error;
    }
  };

  const banUserAccount = async (data) => {
    try {
      const res = await adminAPI.banUser(data);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const suspendUserAccount = async (data) => {
    try {
      const res = await adminAPI.suspendUser(data);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const deleteUserAccount = async (data) => {
    try {
      const res = await adminAPI.deleteUser(data);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const fetchAllReports = async (params = {}) => {
    try {
      const data = await adminAPI.fetchReports(params);

      if (data.success) {
        setReports(data.reports);

        if (data.pagination) {
          setReportsPagination({
            pages: data.pagination.pages,
            page: data.pagination.page,
            total: data.pagination.total,
            limit: data.pagination.limit,
          });
        }
      }

      return data;
    } catch (error) {
      console.error("Error fetching reports:", error);
      throw error;
    }
  };

  const restoreUserAccount = async (data) => {
    try {
      const res = await adminAPI.restoreUser(data);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const fetchUserContentItems = async (userId, options = {}) => {
    try {
      const data = await adminAPI.fetchUserContent(userId, options);
      if (data.success) {
        setUserContent({
          stories: data.stories || [],
          lifeExperiences: data.lifeExperiences || [],
          pagination: data.pagination || {
            page: 1,
            limit: 10,
            total: 0,
            pages: 1,
          },
        });
      }
      return data;
    } catch (error) {
      console.error("Error fetching user content:", error);
      throw error;
    }
  };

  const fetchUserReports = async (userId, options = {}) => {
    try {
      const [filedData, receivedData] = await Promise.all([
        adminAPI.fetchUserReportsFiled(userId, options),
        adminAPI.fetchUserReportsReceived(userId, options),
      ]);

      if (filedData.success && receivedData.success) {
        setUserReports({
          filed: {
            reports: filedData.reports || [],
            pagination: filedData.pagination || {
              page: 1,
              limit: 10,
              total: 0,
              pages: 1,
            },
          },
          received: {
            reports: receivedData.reports || [],
            pagination: receivedData.pagination || {
              page: 1,
              limit: 10,
              total: 0,
              pages: 1,
            },
          },
        });
      }
      return { filedData, receivedData };
    } catch (error) {
      console.error("Error fetching user reports:", error);
      throw error;
    }
  };

  const resolveReportIssue = async (reportId, data) => {
    try {
      const res = await adminAPI.resolveReport(reportId, data);
      return res;
    } catch (error) {
      console.error("Error resolving report:", error);
      throw error;
    }
  };

  const dismissReportIssue = async (reportId, data) => {
    try {
      const res = await adminAPI.dismissReport(reportId, data);
      return res;
    } catch (error) {
      console.error("Error dismissing report:", error);
      throw error;
    }
  };

  const fetchReportDetail = async (reportId) => {
    try {
      const data = await adminAPI.fetchReportDetails(reportId);
      if (data.success) {
        setReportDetails(data.report);
      }
      return data;
    } catch (error) {
      console.error("Error fetching report details:", error);
      throw error;
    }
  };

  const startReportInvestigation = async (reportId) => {
    try {
      const res = await adminAPI.startReportInvestigation(reportId);
      return res;
    } catch (error) {
      console.error("Error starting investigation:", error);
      throw error;
    }
  };

  const fetchUserAdminActions = async (userId) => {
    try {
      const data = await adminAPI.fetchUserAdminActions(userId);
      if (data.success || data.actions) {
        setUserAdminActions(data.actions || []);
      }
      return data;
    } catch (error) {
      console.error("Error fetching user admin actions:", error);
      throw error;
    }
  };

  const fetchContentStats = async () => {
    try {
      const response = await adminAPI.getContentStats();
      if (response && response.success) {
        setContentStats({
          total: response.stats.total.all || 0,
          storiesTotal: response.stats.stories.total || 0,
          lifeExperiencesTotal: response.stats.lifeExperiences.total || 0,
          active: response.stats.total.active || 0,
          flagged: response.stats.total.flagged || 0,
          removed: response.stats.total.removed || 0,
          deleted: response.stats.total.deleted || 0,
        });
      }
      return response;
    } catch (error) {
      console.error("Error fetching content stats:", error);
      throw error;
    }
  };

  const fetchAllContent = async (filters = {}) => {
    try {
      const response = await adminAPI.fetchAllContent(filters);

      if (response && response.success) {
        setContentData({
          items: response.content || [],
        });

        setContentPagination({
          page: response.pagination?.page || 1,
          limit: response.pagination?.limit || 50,
          total: response.pagination?.total || 0,
          pages: response.pagination?.pages || 1,
          storiesTotal: response.pagination?.storiesTotal || 0,
          lifeExperiencesTotal: response.pagination?.lifeExperiencesTotal || 0,
        });
      } else {
        console.error("Unexpected response format:", response);
        setContentData({ items: [] });
        setContentPagination({
          page: 1,
          limit: filters.limit || 50,
          total: 0,
          pages: 1,
        });
      }

      return response;
    } catch (error) {
      console.error("Error fetching content:", error);
      setContentData({ items: [] });
      setContentPagination({
        page: 1,
        limit: filters.limit || 50,
        total: 0,
        pages: 1,
      });
      throw error;
    }
  };

  const getContentDetails = async (contentType, contentId) => {
    try {
      const response = await adminAPI.getContentDetails(contentType, contentId);
      return response.data.content;
    } catch (error) {
      console.error("Error fetching content details:", error);
      throw error;
    }
  };

  const flagContent = async (contentType, contentId, data) => {
    try {
      const response = await adminAPI.flagContent(contentType, contentId, data);
      return response.data;
    } catch (error) {
      console.error("Error flagging content:", error);
      throw error;
    }
  };

  const removeContent = async (contentType, contentId, data) => {
    try {
      const response = await adminAPI.removeContent(
        contentType,
        contentId,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error removing content:", error);
      throw error;
    }
  };

  const restoreContent = async (contentType, contentId, data) => {
    try {
      const response = await adminAPI.restoreContent(
        contentType,
        contentId,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error restoring content:", error);
      throw error;
    }
  };

  const fetchContentDetails = async (contentType, contentId) => {
    try {
      setContentDetails(null); // Clear previous details
      const response = await adminAPI.getContentDetails(contentType, contentId);

      if (response && response.success) {
        setContentDetails(response.content);
      }

      return response;
    } catch (error) {
      console.error("Error fetching content details:", error);
      throw error;
    }
  };

  const fetchContentAdminActions = async (contentType, contentId) => {
    try {
      const response = await adminAPI.fetchContentAdminActions(
        contentType,
        contentId
      );
      if (response && response.actions) {
        setContentAdminActions(response.actions || []);
      }
      return response;
    } catch (error) {
      console.error("Error fetching content admin actions:", error);
      throw error;
    }
  };

  return {
    users,
    userDetails,
    reports,
    reportsPagination,
    pagination,
    userContent,
    userReports,
    reportDetails,
    userAdminActions,
    contentData,
    contentPagination,
    contentStats,
    contentDetails,
    contentAdminActions,
    fetchAllUsers,
    fetchUserDetail,
    approveUserAccount,
    deleteUserAccount,
    banUserAccount,
    suspendUserAccount,
    fetchAllReports,
    restoreUserAccount,
    fetchUserContentItems,
    fetchUserReports,
    resolveReportIssue,
    dismissReportIssue,
    fetchReportDetail,
    startReportInvestigation,
    fetchUserAdminActions,
    fetchAllContent,
    getContentDetails,
    flagContent,
    removeContent,
    restoreContent,
    fetchContentStats,
    fetchContentDetails,
    fetchContentAdminActions,
  };
};

import { adminApi } from "api";
import handleAxiosError from "./handleAxiosError";

export const fetchUsers = async ({
  page = 1,
  limit = 10,
  sort = "-createdAt",
  status,
  role,
  search,
  registrationStatus,
  subscription,
}) => {
  try {
    let queryParams = new URLSearchParams({
      page,
      limit,
      sort,
    });

    if (status) queryParams.append("status", status);
    if (role) queryParams.append("role", role);
    if (search) queryParams.append("search", search);
    if (registrationStatus)
      queryParams.append("registrationStatus", registrationStatus);
    if (subscription) queryParams.append("subscription", subscription);

    const res = await adminApi.get(`/users?${queryParams.toString()}`);
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const fetchUserDetails = async (userId) => {
  try {
    const res = await adminApi.get(`/users/details/${userId}`);
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const approveUser = async (data) => {
  try {
    const res = await adminApi.post(`/users/${data.userId}/approve`, {
      reason: data.reason,
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const banUser = async (data) => {
  try {
    const res = await adminApi.post(`/users/${data.userId}/ban`, {
      reason: data.reason,
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const suspendUser = async (data) => {
  try {
    const res = await adminApi.post(`/users/${data.userId}/suspend`, {
      reason: data.reason,
      durationDays: data.durationDays || 7,
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const deleteUser = async (data) => {
  try {
    const res = await adminApi.post(`/users/${data.userId}/hard-delete`, {
      reason: data.reason,
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const fetchReports = async ({
  page = 1,
  limit = 10,
  sort = "-createdAt",
  status,
  targetType,
  reason,
  reportedUser,
  reporter,
  search,
} = {}) => {
  try {
    let queryParams = new URLSearchParams({
      page,
      limit,
      sort,
    });

    if (status) queryParams.append("status", status);
    if (targetType) queryParams.append("targetType", targetType);
    if (reason) queryParams.append("reason", reason);
    if (reportedUser) queryParams.append("reportedUser", reportedUser);
    if (reporter) queryParams.append("reporter", reporter);
    if (search) queryParams.append("search", search);

    const res = await adminApi.get(`/reports?${queryParams.toString()}`);
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const restoreUser = async (data) => {
  try {
    const res = await adminApi.post(`/users/${data.userId}/restore-account`, {
      reason: data.reason,
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const fetchUserContent = async (
  userId,
  {
    contentType = "all",
    status,
    page = 1,
    limit = 10,
    sort = "-createdAt",
  } = {}
) => {
  try {
    let queryParams = new URLSearchParams({
      page,
      limit,
      sort,
    });

    if (contentType) queryParams.append("contentType", contentType);
    if (status) queryParams.append("status", status);

    const res = await adminApi.get(
      `/users/${userId}/content?${queryParams.toString()}`
    );
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const fetchUserReportsFiled = async (
  userId,
  { page = 1, limit = 10, status } = {}
) => {
  try {
    let queryParams = new URLSearchParams({
      page,
      limit,
    });

    if (status) queryParams.append("status", status);

    const res = await adminApi.get(
      `/users/${userId}/reports/filed?${queryParams.toString()}`
    );
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const fetchUserReportsReceived = async (
  userId,
  { page = 1, limit = 10, status } = {}
) => {
  try {
    let queryParams = new URLSearchParams({
      page,
      limit,
    });

    if (status) queryParams.append("status", status);

    const res = await adminApi.get(
      `/users/${userId}/reports/received?${queryParams.toString()}`
    );
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const resolveReport = async (reportId, { action, notes }) => {
  try {
    const res = await adminApi.post(`/reports/${reportId}/resolve`, {
      action,
      notes,
    });
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const dismissReport = async (reportId, { reason }) => {
  try {
    const res = await adminApi.post(`/reports/${reportId}/dismiss`, {
      reason,
    });
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const fetchReportDetails = async (reportId) => {
  try {
    const res = await adminApi.get(`/reports/${reportId}`);
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const startReportInvestigation = async (reportId) => {
  try {
    const res = await adminApi.post(`/reports/${reportId}/investigate`);
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const fetchUserAdminActions = async (userId) => {
  try {
    const response = await adminApi.get(`/admin-actions`, {
      params: { userId: userId },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching user admin actions:", error);
    throw error;
  }
};

export const fetchAllContent = async ({
  contentType = "all",
  status,
  authorId,
  tag,
  page = 1,
  limit = 50,
  sort = "-createdAt",
  search,
} = {}) => {
  try {
    let queryParams = new URLSearchParams({
      page,
      limit,
      sort,
    });

    if (contentType) queryParams.append("contentType", contentType);
    if (status) queryParams.append("status", status);
    if (authorId) queryParams.append("authorId", authorId);
    if (tag) queryParams.append("tag", tag);
    if (search) queryParams.append("search", search);

    const res = await adminApi.get(`/content?${queryParams.toString()}`);
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

// Add this helper function to normalize content types
const normalizeContentType = (contentType) => {
  if (!contentType) return null;

  // Convert to lowercase and handle various formats
  const type = contentType.toLowerCase();

  if (type === "story") return "Story";
  if (type === "life-experience" || type === "lifeexperience")
    return "LifeExperience";

  return contentType; // Return original if no match (will be validated by server)
};

// Fix flagContent, removeContent, restoreContent, and getContentDetails to use the helper
export const flagContent = async (contentType, contentId, { reason }) => {
  try {
    const normalizedType = normalizeContentType(contentType);
    const res = await adminApi.post(
      `/content/${normalizedType}/${contentId}/flag`,
      {
        reason,
      }
    );
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const removeContent = async (contentType, contentId, { reason }) => {
  try {
    const normalizedType = normalizeContentType(contentType);
    const res = await adminApi.post(
      `/content/${normalizedType}/${contentId}/remove`,
      {
        reason,
      }
    );
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const restoreContent = async (contentType, contentId, { reason }) => {
  try {
    const normalizedType = normalizeContentType(contentType);
    const res = await adminApi.post(
      `/content/${normalizedType}/${contentId}/restore`,
      {
        reason,
      }
    );
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const getContentDetails = async (contentType, contentId) => {
  try {
    const normalizedType = normalizeContentType(contentType);
    const res = await adminApi.get(`/content/${normalizedType}/${contentId}`);
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

// Fix fetchContentAdminActions to use the helper function
export const fetchContentAdminActions = async (contentType, contentId) => {
  try {
    const normalizedType = normalizeContentType(contentType);
    const response = await adminApi.get(`/admin-actions`, {
      params: {
        type: "content",
        contentType: normalizedType,
        targetId: contentId,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching content admin actions:", error);
    throw error;
  }
};

export const getContentStats = async () => {
  try {
    const res = await adminApi.get("/content/stats");
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

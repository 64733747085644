import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  useFloating,
  offset,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  autoPlacement,
  autoUpdate,
  FloatingPortal,
} from "@floating-ui/react";
import styles from "./ReusableActionMenu.module.css";
import cn from "classnames";

const ReusableActionMenu = ({
  options,
  icon: Icon,
  theme = "light",
  buttonClassName = "",
  menuHeader = "Actions",
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isDropdownOpen,
    onOpenChange: setIsDropdownOpen,

    middleware: [
      autoPlacement({
        padding: 10,
        allowedPlacements: [
          "top-start",
          "top-end",
          "bottom-start",
          "bottom-end",
        ],
      }),
      shift(),
      offset(10),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleDropdownToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  };

  const handleMenuItemClick = (e, option) => {
    e.stopPropagation();

    // Don't execute onClick if the option is disabled
    if (!option.disabled && option.onClick) {
      option.onClick();
    }

    setIsDropdownOpen(false); // Close menu after click
  };

  return (
    <div className={styles.dropdownContainer}>
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        className={cn(styles.dropdownButton, buttonClassName, {
          [styles.active]: isDropdownOpen,
        })}
        onClick={handleDropdownToggle}
        aria-label="Open actions menu"
        aria-expanded={isDropdownOpen}
        aria-haspopup="true"
        data-theme={theme}
      >
        <Icon />
      </button>

      <AnimatePresence>
        {isDropdownOpen && (
          <FloatingPortal>
            <motion.div
              ref={refs.setFloating}
              className={styles.dropdownMenu}
              style={floatingStyles}
              {...getFloatingProps()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.1,
                ease: "easeOut",
              }}
              role="menu"
              data-theme={theme}
            >
              {menuHeader && (
                <div className={styles.dropdownHeader} role="presentation">
                  {menuHeader}
                </div>
              )}
              {options.map((option, index) => (
                <div
                  key={index}
                  className={cn(
                    styles.menuItem,
                    option.color && styles[option.color],
                    option.disabled && styles.disabled
                  )}
                  onClick={(e) => handleMenuItemClick(e, option)}
                  role="menuitem"
                  tabIndex={option.disabled ? -1 : 0}
                  onKeyDown={(e) => {
                    if (
                      !option.disabled &&
                      (e.key === "Enter" || e.key === " ")
                    ) {
                      handleMenuItemClick(e, option);
                    }
                  }}
                  title={option.tooltip || ""}
                  aria-disabled={option.disabled}
                >
                  {option.label}
                </div>
              ))}
            </motion.div>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ReusableActionMenu;
